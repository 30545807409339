import posthog from "posthog-js";
import { getAnalytics, logEvent } from "firebase/analytics";

//Logs event in both posthog and firebase
const logEvents = (eventType, eventName) => {
    const analytics = getAnalytics();

    logEvent(analytics, eventType, {name: eventName});
    posthog.capture(eventType, {name: eventName});
};

//Logs event in just firebase
const logEventFirebase = (eventType, eventName) => {
    const analytics = getAnalytics();

    logEvent(analytics, eventType, {name: eventName});
};

export { logEvents,logEventFirebase };