import React from 'react';
import "./JournalRow.css";
import MoodTintPillView from './MoodTintPillView';
import IconCloudLightningFill from './WeatherIcons/IconCloudLightningFill.js';
import IconCloudDrizzleFill from './WeatherIcons/IconCloudDrizzleFill.js';
import IconRainy from './WeatherIcons/IconRainy.js';
import IconCloudSnowFill from './WeatherIcons/IconCloudSnowFill.js';
import IconSunnySharp from './WeatherIcons/IconSunnySharp.js';
import IconCloudyFill from './WeatherIcons/IconCloudyFill.js';

const mapWeatherIcon = (sfSymbolName) => {
  const iconMap = {
    'cloud.bolt.fill': IconCloudLightningFill,
    'cloud.drizzle.fill': IconCloudDrizzleFill,
    'cloud.rain.fill': IconRainy,
    'cloud.snow': IconCloudSnowFill,
    'sun.max.fill': IconSunnySharp,
    'cloud.fill': IconCloudyFill,
  };
  const IconComponent = iconMap[sfSymbolName];
  if (!IconComponent) {
    return IconCloudyFill;
  }
  return IconComponent;
};

const JournalRow = ({ 
    journal, 
    index, 
    clickedJournalId, 
    setExpandedJournalIndex, 
    expandedJournalIndex, 
    handleJournalClick, 
    tintColor }) => {
    const WeatherIcon = mapWeatherIcon(journal.weatherIcon);

    return (
        <div
        key={index}
        className={`journalEntry ${
          clickedJournalId === journal.journal_id
            ? "journalEntry-clicked"
            : ""
        }`}
        data-journal-id={journal.journal_id}
        onMouseDown={() => {
          setExpandedJournalIndex(
            index === expandedJournalIndex ? -1 : index
          );
          handleJournalClick(journal.journal_id);
        }}
      >
        <div className='titleHorizontal'>
        <h3 className="journalTitle">{journal.title}</h3>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="journal-indicator" style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: tintColor,
            marginRight: '10px',
          }}></div>
{journal.weatherIcon ? (
  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
    <WeatherIcon 
      width="24px"
      height="24px"
      style={{ color: '#c1c1c1' }}
      title={journal.weatherIcon}
    />
  </div>
) : (
  <span style={{color: '#c1c1c1'}}>No weather icon</span>
)}
        </div>
        {
            journal.tintColor && journal.intensity ? 
            <MoodTintPillView moodTint={journal.tintColor} intensity={journal.intensity}/> : null
        }
        </div> 
        <small className="journalDate">
          {new Date(journal.updated_at).toDateString()}
        </small>
        {expandedJournalIndex === index && (
          <p className="journalText">{journal.text}</p>
        )}
      </div>
    );
};

export default JournalRow;
